import { render, staticRenderFns } from "./optionalTest.vue?vue&type=template&id=3bab28ae&scoped=true&"
import script from "./optionalTest.vue?vue&type=script&lang=js&"
export * from "./optionalTest.vue?vue&type=script&lang=js&"
import style0 from "./optionalTest.vue?vue&type=style&index=0&id=3bab28ae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bab28ae",
  null
  
)

export default component.exports