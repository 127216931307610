<template>
  <div class="scanaBnormalPaper">
    <div class="header">
      {{ sheetInfomation.examName }} /
      {{ sheetInfomation.subjectName }}
    </div>
    <!-- tab列表 -->
    <scanErrTable
      ref="scanErrTable"
      :sheet-infomation="sheetInfomation"
    ></scanErrTable>
    <!-- 除开客观题异常的所有处理 -->
    <div
      v-if="
        GET_INDEX_SCAN_TYPE != 14 &&
        GET_INDEX_SCAN_TYPE != 16 &&
        GET_INDEX_SCAN_TYPE != 18
      "
      class="operation-box"
    >
      <scanCheckBox @getErrorList="getErrorList"></scanCheckBox>
      <sacnErrEdit
        :sheet-infomation="sheetInfomation"
        @getErrorList="getErrorList"
      ></sacnErrEdit>
    </div>

    <!-- 客观题异常 主观题异常 -->
    <template v-else>
      <!-- v-if="GET_INDEX_SCAN_TYPE == 14" -->
      <objectiveAnomaly
        v-if="GET_INDEX_SCAN_TYPE == 14"
        ref="objectiveAnomaly"
      ></objectiveAnomaly>
      <optionalTest
        v-if="GET_INDEX_SCAN_TYPE == 18"
        ref="optionalTest"
      ></optionalTest>
      <SubjectiveAnomaly
        v-if="GET_INDEX_SCAN_TYPE == 16"
        @newRrrList="newRrrList"
      ></SubjectiveAnomaly>
    </template>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div>
        1、请根据客观题实际填涂结果修改，每页修改完成后，请点击“提交本页”。
        <br />
        2、若发现处理错了，已处理中支持二次修改。
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- <el-dialog title="原卷" :visible.sync="dialogVisibleImg" width="70%">
      <div class="model-img">
        <img v-for="(v, i) in imgUrl" :key="i" :src="v" alt="" />
      </div>
    </el-dialog> -->

    <!-- 修订记录 -->
    <el-dialog
      title="扫描页面功能修改"
      :visible.sync="versionUpdating"
      class="version-updating"
      width="548px"
      :show-close="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="massage edit-scroll-style">
        <div style="line-height: 21px">
          1.取消 “滚动鼠标滚轮键” 放大缩小图片功能；
        </div>
        <div style="line-height: 21px">
          2.增加“ctrl键+滚动鼠标滚轮键”放大缩小图片功能；
        </div>
        <div style="line-height: 21px">
          3.增加“shift键+滚动鼠标滚轮键”左右滑动图片功能；
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="versionUpdating = false">
          我知道了
        </el-button>
      </span>
    </el-dialog>
    <scanRecognition ref="scanRecognition"></scanRecognition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  scanrecordAnswersheet,
  getErrorList,
  getABtype,
  getSubjects,
} from "@/core/api/exam/examScanSheet";

import scanErrTable from "./components/scanErrTable";
import scanCheckBox from "./components/scanCheckBox";
import sacnErrEdit from "./components/sacnErrEdit";
import optionalTest from "./components/optionalTest";
import scanRecognition from "./components/scanRecognition";
import SubjectiveAnomaly from "./components/SubjectiveAnomaly";

import objectiveAnomaly from "./components/objectiveAnomaly";
import { exampapersheetmarkList } from "@/core/api/exam/sheet";
import { getStore, setStore } from "@/core/util/store";
export default {
  name: "ScanaBnormalPaper",
  components: {
    scanCheckBox,
    scanErrTable,
    sacnErrEdit,
    SubjectiveAnomaly,
    optionalTest,
    scanRecognition,
    objectiveAnomaly,
  },
  data() {
    return {
      versionUpdating: false,

      dialogVisibleImg: false,

      sheetInfomation: {},
      dialogVisible: false,
      // 客观题异常处理
    };
  },

  computed: {
    // GET_ABNORMAL_LIST GET_INDEX_SCAN_TYPE
    ...mapGetters([
      "GET_INDEX_SCAN_TYPE",
      "GET_IS_HANDLE",
      "GET_ABNORMAL_LIST",
      "GET_CURRENT",
      "GET_SCAN_ERR_LIST",
      "GET_INDEX_SCAN_ERR",
    ]),
  },
  watch: {
    GET_INDEX_SCAN_TYPE() {
      this.getErrorList();
    },
  },
  created() {
    this.scanrecordAnswersheet();
    this.getErrorList();
    this.getSubjects();
    this.getShowVS();
    this.exampapersheetmarkList();
  },
  methods: {
    getShowVS() {
      let version = "1";
      let temVs = getStore({ name: "template_version" });

      if (!temVs || version != temVs) {
        this.versionUpdating = true;
      }
      setStore({ name: "template_version", content: version });
    },
    exampapersheetmarkList() {
      let data = {
        sheetUuid: this.$route.query.sheetUuid,
      };
      exampapersheetmarkList(data).then((res) => {
        this.$store.commit("SET_SHEET_MARK_LIST", res.data.data);
        this.$store.commit("SET_INDEX_TIME", new Date().getTime());
      });
    },
    goback() {
      this.$router.go(-1);
    },

    // 获取科目列表
    getSubjects() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      getSubjects(data).then((res) => {
        let arr = [];
        res.data.data.map((item) => {
          arr.push({
            label: item.subjectName,
            value: item.id,
          });
        });
        this.$store.commit("SET_SUBJECT_LIST", arr);
      });
    },
    // 获取ab卷异常
    getABtype(data) {
      if (data.isHandle == 0) {
        data.type = 3;
      } else {
        data.type = this.absent || 3;
      }
      data.current = this.GET_CURRENT;
      // data.size = this.size;
      data.size = this.size;
      getABtype(data).then((res) => {
        this.$refs.scanErrTable.errortypeNums();
        this.$store.commit("SET_SCAN_ERR_LIST", res.data.data.records);
        this.$store.commit("SET_TOTAL", res.data.data.total);
        this.$store.commit("SET_CURRENT", this.GET_CURRENT);
      });
    },
    // 获取异常列表
    getErrorList(val) {
      if (this.GET_INDEX_SCAN_TYPE == 14) {
        this.$nextTick(() => {
          this.$refs.objectiveAnomaly.basequestionexceptions();
        });

        this.$refs.scanErrTable.errortypeNums();
        return;
      }
      let data = {
        errType: this.GET_INDEX_SCAN_TYPE || 2,
        examPaperId: this.$route.query.examPaperId,
        isHandle: this.GET_IS_HANDLE,
      };
      if (val) {
        this.absent = val;
      }
      if (this.GET_INDEX_SCAN_TYPE == 13 && this.GET_IS_HANDLE) {
        data.type = val;
      }
      if (this.GET_INDEX_SCAN_TYPE == 11) {
        // data.type = val || 0;
        this.getABtype(data, val);
        return;
      }

      getErrorList(data).then((res) => {
        this.$refs.scanErrTable.errortypeNums();
        if (this.GET_INDEX_SCAN_TYPE == 2) {
          if (res.data.data.scanRecordErrVOList.length > 0) {
            this.$store.commit(
              "SET_SCAN_ERR_LIST",
              res.data.data.scanRecordErrVOList
            );
            this.$store.commit(
              "SET_INDEX_SCAN_ERR",
              res.data.data.scanRecordErrVOList[0]
            );
          } else {
            this.$store.commit("SET_SCAN_ERR_LIST", []);
            this.$store.commit("SET_INDEX_SCAN_ERR", {});
          }
        } else {
          if (res.data.data.length > 0) {
            this.$store.commit("SET_SCAN_ERR_LIST", res.data.data);
            this.$store.commit("SET_INDEX_SCAN_ERR", res.data.data[0]);
          } else {
            this.$store.commit("SET_SCAN_ERR_LIST", []);
            this.$store.commit("SET_INDEX_SCAN_ERR", {});
          }
        }
      });
    },
    newRrrList() {
      this.$refs.scanErrTable.errortypeNums();
    },
    scanrecordAnswersheet() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      scanrecordAnswersheet(data).then((res) => {
        this.sheetInfomation = res.data.data;
        this.$store.commit("SET_SHEET_INFOMATION", this.sheetInfomation);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.scanaBnormalPaper {
  position: relative;
  min-width: 1509px;

  .model-img {
    height: calc(100vh - 400px);
    overflow-y: scroll;
    img {
      width: 100%;
    }
  }

  .header {
    background-color: $primary-color;
    padding: 10px 24px;
    color: #ffffff;
    cursor: pointer;
    // display: flex;
    // justify-content: space-between;
  }
  .operation-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
</style>
