<template>
  <div class="SubjectiveAnomaly">
    <div class="anomaly-top">
      <div class="anomaly-top-left">
        <!-- {{ typeBtnList }} -->
        <div
          v-for="(v, i) in typeBtnList"
          :key="i"
          class="anomaly-top-left-btn"
          :class="{
            'anomaly-top-left-btn-on': indexTypes == 1 && indextype == v.value,
          }"
          @click="checkValue(v.value, 1)"
        >
          {{ v.label }}（{{ v.number }}）
        </div>
        <el-button
          v-if="indextype == 2"
          style="margin-left: 12px"
          type="primary"
          @click="submitAll()"
          >批量提交未评</el-button
        >
      </div>
      <div class="anomaly-top-right">
        <div
          v-for="(v, i) in checkBtnList"
          :key="i"
          class="anomaly-top-right-btn"
          :class="{
            'anomaly-top-right-btn-on':
              indexTypes == 2 && indexCheck == v.value,
          }"
          @click="checkValue(v.value, 2)"
        >
          {{ v.label }}
          <i v-if="v.value == 1" class="el-icon-check"></i>
          <i v-if="v.value == 0" class="el-icon-close"></i>（{{ v.number }}）
        </div>

        <div
          v-for="(v, i) in scoreBtnList"
          :key="i + 2"
          class="anomaly-top-right-btn"
          :class="{
            'anomaly-top-right-btn-on':
              indexTypes == 3 && indexScore == v.answerScore,
          }"
          @click="checkValue(v.answerScore, 3)"
        >
          {{ v.answerScore }}分（{{ v.count }}）
        </div>
      </div>
    </div>
    <div v-loading="listLoading" class="anomaly-img-box">
      <el-row :gutter="24">
        <el-col
          v-for="(v, i) in questionList"
          :key="i"
          :index-check="indexCheck"
          :span="indexCheck !== '' ? 4 : 8"
        >
          <div
            class="img-list-box"
            :class="{ 'img-border': v.isFillBlank == 1 }"
          >
            <!-- <el-popover
              placement="bottom"
              width="235"
              popper-class="edit-poprver"
              @hide="hidden(v)"
              @show="show(v)"
            > -->
            <div class="show-tips-box stop-img">
              <div v-if="v.visible" class="tips-box-style">
                <div class="tips-box-top">
                  <span>姓名：{{ v.studentName }}</span>
                  <span>考号：{{ v.studentExamId }}</span>
                </div>
                <div
                  v-if="!v.isFillBlank || v.isFillBlank == 0"
                  class="tips-box-top"
                >
                  <span>{{ v.questionNum }}题({{ v.score }}分)</span>
                  <el-input
                    :ref="'inputRef' + i"
                    v-model="v.answerScore"
                    placeholder=""
                    oninput='value = (value.match(/^[0-9]+(\.[05]{0,1})?/g) ?? [""])[0]'
                    @blur="v.answerScore = $event.target.value"
                    @input="setScore(v)"
                    @keyup.enter.native="handleClick(v)"
                  ></el-input>
                  <span>分</span>
                </div>
                <!-- isFillBlank -->
                <div v-if="v.isFillBlank == 1" class="btn-list-box">
                  <!-- 本题结果： -->
                  <span>{{ v.questionNum }}题 </span>
                  <el-button
                    type="info"
                    class="list-btn"
                    :class="{ 'on-btn': v.answerText == 1 }"
                    @click="checkBtn(v, 1)"
                  >
                    <img src="@/static/scanSheet/succ.png" alt="" />
                    <img class="on" src="@/static/scanSheet/succ.png" alt="" />
                  </el-button>
                  <el-button
                    type="info"
                    class="list-btn"
                    :class="{ 'on-btn1': v.answerText == 0 }"
                    @click="checkBtn(v, 0)"
                  >
                    <img src="@/static/scanSheet/err.png" alt="" />
                    <img class="on" src="@/static/scanSheet/err.png" alt="" />
                  </el-button>
                </div>
                <div class="btn-box">
                  <el-button type="text" @click="showImgFn(v)"
                    >查看原卷</el-button
                  >
                  <!-- <el-image
                class="btn-img"
                :src="v.answerUrl"
                :preview-src-list="v.srcList"
              >
                <el-button type="text">查看原卷</el-button>
                <span class="demonstration">自定义</span>
              </el-image> -->
                </div>
              </div>
              <img
                slot="reference"
                class="add-img-style"
                :src="v.answerUrl"
                alt=""
                @click="itemClick(v, i)"
              />
            </div>
            <!-- <el-button >删除</el-button> -->
            <!-- </el-popover> -->
          </div>
        </el-col>
      </el-row>
    </div>
    <el-pagination
      v-if="total > 27"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <showImgList
      ref="showImgList"
      :key="newRefKey"
      :src-list="srcList"
    ></showImgList>
    <!-- <div v-if="showImg" class="show-img-box">
      <div class="show-img-cnt-box">
        <img :src="srcList[indexSrc]" alt="" />
        <div class="viewer__close view_btn" @click="getOld()">
          <i class="el-icon-close"></i>
        </div>
        <div class="viewer__prev view_btn" @click="indexSrc = 0">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="viewer__next view_btn" @click="indexSrc = 1">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import { number } from "echarts";
const typeBtnList = [
  {
    label: "超分",
    value: 3,
    number: 0,
  },
  {
    label: "未评",
    value: 2,
    number: 0,
  },
  {
    label: "已处理",
    value: 1,
    number: 0,
  },
];
const checkBtnList = [
  {
    label: "填空题",
    value: 0,
    number: 0,
  },
  {
    label: "填空题",
    value: 1,
    number: 0,
  },
];
const scoreBtnList = [];

import {
  basesubquestionexceptions,
  submitSubquestionexceptions,
  isfillblank,
  scorelist,
  subquestionexceptions,
  subquestionexceptionsBatch,
} from "@/core/api/exam/examScanSheet";
import showImgList from "@/components/showImgList";
export default {
  name: "SubjectiveAnomaly",
  components: { showImgList },
  data() {
    return {
      visible: false,
      listLoading: false,
      typeBtnList: typeBtnList,
      checkBtnList: checkBtnList,
      scoreBtnList: scoreBtnList,
      indexTypes: 1,
      score: "",
      indextype: 3,
      indexCheck: "",
      indexScore: 1,
      questionList: [],
      pageIndex: 1,
      pageSize: 500,
      // pageTotal: 1,
      // size: 6,
      total: 0,
      indexSrc: 0,
      newRefKey: 1,
      srcList: [],
      showImg: false,
    };
  },
  created() {
    this.isfillblank();
    this.basesubquestionexceptions();
    this.scorelist();
    this.subquestionexceptions();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleDocumentClick);
  },
  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  },
  methods: {
    hasAncestorWithClass(element, className) {
      let parent = element.parentElement;
      while (parent !== null) {
        if (parent.classList.contains(className)) {
          return true;
        }
        parent = parent.parentElement;
      }
      return false;
    },
    handleDocumentClick(event) {
      const clickedElement = event.target; // 获取点击的元素
      const className = "stop-img";
      if (!this.hasAncestorWithClass(clickedElement, className)) {
        if (this.indexQuestion && this.indexQuestion.id) {
          this.questionList.map((item) => {
            if (this.indexQuestion.id == item.id) {
              this.submitSubquestionexceptions(item);
            }
          });
        }
      }
      // console.log();
      // console.log("页面上的某个地方被点击了：", event.target);
    },
    submitAll() {
      this.$confirm(
        "请确认下方都为'未评'才提交,否则会导致分数错乱!",
        "温馨提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              this.setsubquestionexceptionsBatch(instance, done);
              // setTimeout(() => {
              //   done();
              //   setTimeout(() => {
              //     instance.confirmButtonLoading = false;
              //   }, 300);
              // }, 3000);
            } else {
              done();
            }
          },
        }
      )
        .then(() => {})
        .catch(() => {});
    },
    setsubquestionexceptionsBatch(instance, done) {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      subquestionexceptionsBatch(data)
        .then(() => {
          this.isfillblank();
          this.basesubquestionexceptions();
          this.scorelist();
          this.subquestionexceptions();
          this.$emit("newRrrList");
          this.$message({
            message: "分数提交成功!",
            type: "success",
            showClose: true,
          });
          instance.confirmButtonLoading = false;
          done();
        })
        .catch(() => {
          instance.confirmButtonLoading = false;
        });
    },
    itemClick(v, i) {
      // 添加判断  如果之前还有没有关闭的  就先提交下;
      let array = this.questionList.filter((item) => item.visible);
      if (array.length > 0) {
        this.submitSubquestionexceptions(array[0]);
        return;
      }
      if (v.answerScore < 0) {
        v.answerScore = 0;
      }

      v.visible = true;
      this.indexQuestion = JSON.parse(JSON.stringify(v));
      if (v.isFillBlank == 1) return;
      // console.log(v);
      let inputRefStr = "inputRef" + i;
      setTimeout(() => {
        // console.log(this.$refs[inputRefStr]);
        if (this.$refs[inputRefStr]) {
          this.$refs[inputRefStr][0].focus();
        }
      }, 500);

      // console.log(v);
    },
    getOld() {
      // console.log(this.oldQuestionList);
      this.showImg = false;
      setTimeout(() => {
        this.questionList = this.oldQuestionList;
      }, 1);
    },
    showImgFn(item) {
      // this.newRefKey = new Date().getTime();
      this.showImg = true;
      this.srcList = JSON.parse(JSON.stringify(item.srcList));
      this.$refs.showImgList.showImg = true;
      this.oldQuestionList = JSON.parse(JSON.stringify(this.questionList));
    },
    // 翻页start
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.subquestionexceptions();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.subquestionexceptions();
      // console.log(`当前页: ${val}`);
    },
    // 分数列表
    scorelist() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      scorelist(data).then((res) => {
        // console.log(res);
        this.scoreBtnList = res.data.data;
      });
    },
    // 分页查询主观题-小题详情
    subquestionexceptions() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        examId: this.$route.query.examId,
      };
      if (this.indexTypes == 1) {
        data.questionExceptionStatus = this.indextype;
      }
      if (this.indexTypes == 2) {
        data.answerText = this.indexCheck;
      }
      if (this.indexTypes == 3) {
        data.score = this.indexScore;
      }
      let pageData = {
        current: this.pageIndex,
        size: this.pageSize,
      };
      this.listLoading = true;
      subquestionexceptions(data, pageData)
        .then((res) => {
          this.listLoading = false;
          this.indexQuestion = {};
          res.data.data.records = res.data.data.records.map((index) => {
            index.visible = false;
            index.srcList = index.urls.split("，");
            return index;
          });

          this.questionList = res.data.data.records;
          this.oldQuestionList = JSON.parse(
            JSON.stringify(res.data.data.records)
          );
          this.total = res.data.data.total;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    // 主观题异常数量
    basesubquestionexceptions() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      basesubquestionexceptions(data).then((res) => {
        // console.log(res);
        this.typeBtnList.map((item) => {
          res.data.data.map((index) => {
            if (item.value == index.questionExceptionStatus) {
              item.number = index.count;
            }
          });
        });
      });
    },
    submitSubquestionexceptions(item) {
      if (item.answerScore <= 0) {
        item.answerScore = 0;
      }
      submitSubquestionexceptions(item)
        .then(() => {
          // console.log(res);
          this.$message({
            message: "修改成功！",
            type: "success",
            showClose: true,
          });
          this.isfillblank();
          this.basesubquestionexceptions();
          this.scorelist();
          this.subquestionexceptions();
          this.$emit("newRrrList");
        })
        .catch(() => {
          this.subquestionexceptions();
        });
    },
    // 填空题异常数量
    isfillblank() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      isfillblank(data).then((res) => {
        // console.log(res);
        if (res.data.data) {
          res.data.data.map((item) => {
            this.checkBtnList.map((index) => {
              if (item.answerText == index.value) {
                index.number = item.count;
              }
            });
          });
          if (res.data.data.length == 0) {
            this.checkBtnList = [];
          }
        }
      });
    },
    setScore(item) {
      // console.log(Number("12.") == 12);
      // console.log(this.score);
      if (item.score < item.answerScore) {
        item.answerScore = item.answerScore
          .toString()
          .substring(0, item.answerScore.length - 1);
      }
    },
    checkValue(val, type) {
      this.indexTypes = type;
      this.pageIndex = 1;
      if (type == 1) {
        this.indextype = val;
        this.indexCheck = "";
        this.indexScore = "";
      }
      if (type == 2) {
        this.indextype = "";
        this.indexCheck = val;
        this.indexScore = "";
      }
      if (type == 3) {
        this.indextype = "";
        this.indexCheck = "";
        this.indexScore = val;
      }
      this.subquestionexceptions();
      // console.log(val);
    },
    handleClick(item) {
      this.subType = true;

      this.submitSubquestionexceptions(item);
    },
    checkBtn(item, type) {
      item.answerText = type;
      this.subType = true;
      if (type == 1) {
        item.answerScore = 0;
      } else {
        item.answerScore = item.score;
      }
      this.submitSubquestionexceptions(item);
    },
    hidden(item) {
      // || item.isFillBlank == 1 先注释判断题判断
      if (this.subType) {
        return;
      }
      this.oldQuestionList.map((index) => {
        if (index.id == item.id) {
          // if (item.answerScore != index.answerScore) {
          this.submitSubquestionexceptions(item);
          // }
        }
      });
    },
    show() {
      this.subType = false;
    },
  },
};
</script>
<!-- <style lang="scss">
.edit-poprver {

  .popper__arrow {
    display: none;
  }
}
</style> -->
<style scoped lang="scss">
.SubjectiveAnomaly {
  .show-img-box {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200000;
    img {
      display: block;
      margin: 0 auto;
      max-height: 100%;
      max-width: 100%;
    }
    .show-img-cnt-box {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      background: rgba(0, 0, 0, 0.5);
      .view_btn {
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        opacity: 0.8;
        cursor: pointer;
        box-sizing: border-box;
        user-select: none;
      }
      .viewer__prev {
        left: 40px;
        text-indent: 2px;
        top: 50%;
        transform: translateY(-50%);
        width: 44px;
        height: 44px;
        font-size: 24px;
        color: #fff;
        background-color: #606266;
        border-color: #fff;
      }
      .viewer__next {
        right: 40px;
        text-indent: 2px;
        top: 50%;
        transform: translateY(-50%);
        width: 44px;
        height: 44px;
        font-size: 24px;
        color: #fff;
        background-color: #606266;
        border-color: #fff;
      }
      .viewer__close {
        top: 40px;
        right: 40px;
        width: 40px;
        height: 40px;
        font-size: 24px;
        color: #fff;
        background-color: #606266;
      }
    }
  }
  .el-pagination {
    margin: 0 142px;
  }
  .anomaly-img-box {
    padding: 18px 142px;
    .img-list-box {
      padding: 8px;
      border: 1px dashed #ffffff;
      background-color: #fafafa;
      cursor: pointer;
      flex-shrink: 0;
      margin-bottom: 24px;
      text-align: center;
      height: 43px;
      .add-img-style {
        max-width: 100%;
        height: 100%;
      }
    }
    .img-border {
      border: 1px dashed #000000;
    }
  }
  .anomaly-top {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    .anomaly-top-right {
      display: flex;
      align-items: center;
      padding: 0 24px;
      flex-wrap: wrap;

      .anomaly-top-right-btn {
        color: #2474ed;
        padding: 7px 8px;
        border-radius: 4px;
        margin: 4px 0;
        cursor: pointer;
        border: 1px solid;
        margin-right: 8px;
        i {
          color: #2474ed;
          font-size: 16px;
        }
      }
      .anomaly-top-right-btn-on {
        background-color: #2474ed;
        color: #ffffff;
        i {
          color: #ffffff;
          //   font-size: 16px;
        }
      }
      // .anomaly-top-right-btn + .anomaly-top-right-btn {
      //   margin-right: 8px;
      // }
    }
    .anomaly-top-left {
      display: flex;

      padding: 17px 24px;
      border-right: 1px solid #d5d6db;
      flex-shrink: 0;
    }
    .anomaly-top-left-btn {
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d5d6db;
      padding: 6px 10px;

      cursor: pointer;
    }
    .anomaly-top-left-btn-on {
      background-color: #f56c6b;
      border: 1px solid #f56c6b;
      color: #ffffff;
    }
    .anomaly-top-left-btn + .anomaly-top-left-btn {
      margin-left: 8px;
    }
  }
  .show-tips-box {
    position: relative;
    .tips-box-style {
      width: 250px;
      position: absolute;
      padding: 10px 12px;
      left: 50%;
      top: 100%;
      z-index: 100;
      transform: translateX(-50%);
      background: #f4fbfd;
      box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
    }

    .on-btn {
      background-color: #68c23a;
      border-color: #68c23a;
    }
    .on-btn1 {
      background-color: #f56c6b;
      border-color: #f56c6b;
    }
    .list-btn {
      padding: 8px 16px;
    }
    .tips-box-style {
      font-size: 12px;
      letter-spacing: 0;
      .btn-box {
        margin: 12px auto;
        text-align: center;
      }
      .btn-list-box {
        padding: 6px 0;
      }
      .tips-box-top {
        display: flex;
        justify-content: space-between;
        // font-size: 12px;
        align-items: center;
        padding: 6px 0;
        .el-input {
          width: 80px;
        }
        .el-input__inner {
          line-height: 32px;
          height: 32px;
        }
      }
    }
  }
}
</style>
