<template>
  <div class="scanRecognition">
    <el-dialog
      title="参数设置"
      :visible.sync="dialogVisible"
      width="590px"
      :close-on-click-modal="false"
    >
      <div>
        <el-steps :active="active" align-center>
          <el-step title="设置参数" @click.native="active = 1"></el-step>
          <el-step
            title="选择题组"
            @click.native="active == 3 ? (active = 2) : ''"
          ></el-step>
          <el-step title="选择答题卡类型"></el-step>
        </el-steps>
        <el-form
          v-if="active == 1"
          ref="submitFrom"
          :model="submitFrom"
          label-width="160px"
          :rules="rules"
        >
          <el-form-item label="最大灰度值：" prop="maximumGray">
            <el-input
              v-model="submitFrom.maximumGray"
              :max="255"
              :min="0"
            ></el-input>
          </el-form-item>
          <el-form-item label="答题卡识别比率：" prop="sheet">
            <el-input v-model="submitFrom.sheet"></el-input>
          </el-form-item>
          <el-form-item label="缺考标记识别比率：" prop="absent">
            <el-input v-model="submitFrom.absent"></el-input>
          </el-form-item>
          <el-form-item label="选做题识别比率：" prop="optional">
            <el-input
              v-model="submitFrom.optional"
              :max="1"
              :min="0"
            ></el-input>
          </el-form-item>
          <el-form-item label="A/B的卷识别比率：" prop="ABType">
            <el-input v-model="submitFrom.ABType"></el-input>
          </el-form-item>
          <el-form-item label="客观题识别比率：" prop="objective">
            <el-input
              v-model="submitFrom.objective"
              :max="1"
              :min="0"
            ></el-input>
          </el-form-item>
          <el-form-item label="准考证号识别比率：" prop="sheetId">
            <el-input v-model="submitFrom.sheetId"></el-input>
          </el-form-item>
        </el-form>
        <div v-if="active == 2" class="check-box">
          <div style="margin: 32px 0 18px">
            <el-button type="text" @click="check(1)">全选</el-button>
            <el-button type="text" @click="check(2)">反选</el-button>
            <el-button type="text" @click="check(3)">取消</el-button>
          </div>
          <el-checkbox-group v-model="groupList" class="edit-scroll-style">
            <el-checkbox
              v-for="(v, i) in basequestionList"
              :key="i"
              :label="v.questionGroupId"
              >{{ v.questionNumGroup }}</el-checkbox
            >
          </el-checkbox-group>
          <!-- basequestionList -->
        </div>
        <div v-if="active == 3" style="margin: 24px 0">
          <el-alert
            title="重新识别仅针对于存在大量定位异常中，如果只是客观题颜色深浅不需要重新识别"
            type="error"
            effect="dark"
          >
          </el-alert>
          <el-radio-group v-model="recognition">
            <el-radio label="1">全部答题卡</el-radio>
            <el-radio label="2">客观题未涂卡</el-radio>
            <el-radio label="3">客观题多涂卡</el-radio>
            <el-radio label="3">客观题异常涂卡</el-radio>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="submit">下 一 步</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ScanRecognition",
  components: {},
  data() {
    const maximumGray = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入灰度值"));
      }
      value = Number(value);
      if (!value && value !== 0) {
        callback(new Error("请输入数字值"));
      } else {
        if (value > 255 || value < 1) {
          callback(new Error("灰度值为1-255之间"));
        } else {
          callback();
        }
      }
    };
    const rulesType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入"));
      }
      value = Number(value);
      //   console.log(value);
      if (!value && value !== 0) {
        callback(new Error("请输入数字"));
      } else {
        if (value >= 0 && value <= 1) {
          callback();
        } else {
          return callback(new Error("请输入0-1之间的值"));
        }
      }
    };
    return {
      dialogVisible: false,
      active: 1,
      basequestionList: [],
      groupList: [],
      recognition: 0,
      submitFrom: {
        maximumGray: 200,
        sheet: 0.7,
        absent: 0.7,
        optional: 0.7,
        ABType: 0.7,
        objective: 0.7,
        sheetId: 0.7,
      },
      rules: {
        maximumGray: [
          //   { required: true, message: "请输入", trigger: "blur" },
          { validator: maximumGray, trigger: "blur" },
        ],
        absent: [{ validator: rulesType, trigger: "blur" }],
        sheet: [{ validator: rulesType, trigger: "blur" }],
        optional: [{ validator: rulesType, trigger: "blur" }],
        ABType: [{ validator: rulesType, trigger: "blur" }],
        objective: [{ validator: rulesType, trigger: "blur" }],
        sheetId: [{ validator: rulesType, trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    check(type) {
      // this.groupList
      if (type == 1) {
        this.groupList = [];
        this.basequestionList.map((item) => {
          this.groupList.push(item.questionGroupId);
        });
      }
      if (type == 2) {
        let array = this.basequestionList.filter(
          (item) => this.groupList.indexOf(item.questionGroupId) == -1
        );
        this.groupList = [];
        array.map((item) => {
          this.groupList.push(item.questionGroupId);
        });
      }
      if (type == 3) {
        this.groupList = [];
      }
    },
    openTips() {
      this.$confirm("正在执行重新识别，请完成识别后查看…", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(() => {})
        .catch(() => {});
    },
    submit() {
      if (this.active == 1) {
        this.$refs.submitFrom.validate((valid) => {
          if (valid) {
            this.active++;
            this.basequestionList = JSON.parse(
              JSON.stringify(this.$parent.basequestionList)
            );
            // console.log(this.basequestionList);
            return;
          } else {
            return false;
          }
        });
      } else if (this.active == 2) {
        this.active++;
      } else {
        if (!this.recognition) {
          this.$message({
            message: "请选择需要重新识别的试题",
            type: "warning",
          });
          return;
        }
        this.dialogVisible = false;
        this.openTips();
      }
    },
    init() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
.scanRecognition {
  .edit-scroll-style {
    max-height: 200px;
    // height: 300px;
  }
  ::v-deep .el-form-item__error {
    white-space: nowrap;
    margin-top: -0.5em;
  }
  .el-steps--horizontal {
    width: 116%;
    margin-left: -10%;
  }
  .edit-scroll-style {
    .el-checkbox {
      width: 186px;
      margin-right: 0;
    }
  }
  .check-box {
    padding-left: 4px;
    margin-top: 12px;
  }
  ::v-deep .el-dialog__body {
    padding: 20px 0 0 20px;
    .el-form-item__label {
      padding: 0;
    }
  }
  ::v-deep .el-dialog__footer {
    text-align: center;
  }
  ::v-deep .el-step__line-inner {
    border: 1px dashed #c0c4cc;
    background-color: #ffffff;
  }
  ::v-deep .el-step__line {
    border: 1px dashed #c0c4cc;
    background-color: #ffffff;
  }
  ::v-deep .is-finish {
    .el-step__line {
      border: 1px dashed;
      //   border: none;
    }
    .el-step__line-inner {
    }
  }
  .el-form {
    padding: 32px 0;
    display: flex;
    flex-wrap: wrap;
    .el-form-item {
      width: 45%;
      margin-bottom: 8px;
      .el-input {
        width: 80px;
      }
    }
  }
}
</style>
