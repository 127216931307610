<template>
  <div class="sacnErrEdit">
    <div class="sacn-err-header">
      <div class="sacn-err-header-title">{{ sheetInfomation.schoolName }}</div>
      <div class="check-tab">
        <!-- {{ GET_ABNORMAL_LIST }} -->
        <div v-if="recycleBin">回收站</div>
        <!-- 如果是考号填涂异常 之后的 才会有已处理 -->

        <template v-else>
          <template
            v-if="GET_INDEX_SCAN_TYPE > 5 && GET_ABNORMAL_LIST.length > 0"
          >
            <div
              :class="{ 'check-tab-on': GET_IS_HANDLE == 0 }"
              @click="checkHander(0)"
            >
              待处理：（{{
                GET_ABNORMAL_LIST.filter(
                  (i) => i.value == GET_INDEX_SCAN_TYPE
                )[0].number
              }}）
            </div>
            <div
              :class="{ 'check-tab-on': GET_IS_HANDLE == 1 }"
              @click="checkHander(1)"
            >
              已处理：（{{
                GET_ABNORMAL_LIST.filter(
                  (i) => i.value == GET_INDEX_SCAN_TYPE
                )[0].handnumber
              }}）
            </div>
          </template>
          <template v-else>
            <div v-if="GET_ABNORMAL_LIST.length > 0">
              待处理：（{{
                GET_ABNORMAL_LIST.filter(
                  (i) => i.value == GET_INDEX_SCAN_TYPE
                )[0].number
              }}）
            </div>
          </template>
        </template>
      </div>
      <!-- 如果是缺考  单独处理 -->
      <div v-if="GET_INDEX_SCAN_TYPE != 13">
        <template v-if="!isBatch"
          >| <span @click="isBatch = true">批量操作</span>
        </template>
        <template v-else>
          <el-checkbox v-model="checkedAll" @change="changeCheck">
            全选
          </el-checkbox>
          | <span @click="isBatch = false">批量操作</span></template
        >
      </div>
    </div>
    <div class="edit-scroll-style paper-item-box">
      <!-- {{ GET_INDEX_SCAN_TYPE }} -->
      <!-- 缺考异常 A-B卷异常 单独处理 -->
      <template v-if="GET_INDEX_SCAN_TYPE == 13 || GET_INDEX_SCAN_TYPE == 11">
        <div v-if="GET_IS_HANDLE == 0" class="paper-item on">
          全部（{{
            GET_ABNORMAL_LIST.filter((i) => i.value == GET_INDEX_SCAN_TYPE)[0]
              .number
          }}）
          <i class="el-icon-caret-left"></i>
        </div>
        <template v-else>
          <!-- ab卷异常 -->
          <template v-if="GET_INDEX_SCAN_TYPE == 11">
            <div
              class="paper-item absent-list"
              :class="{ on: absent == 3 }"
              @click="checkAbsent(3)"
            >
              全部
              <i class="el-icon-caret-left"></i>
            </div>
            <div
              class="paper-item absent-list"
              :class="{ on: absent == 1 }"
              @click="checkAbsent(1)"
            >
              A卷 <span>{{ absentNums }}</span>
              <i class="el-icon-caret-left"></i>
            </div>
            <div
              class="paper-item absent-list"
              :class="{ on: absent == 2 }"
              @click="checkAbsent(2)"
            >
              B卷 <span>{{ unAbsentNums }}</span>
              <i class="el-icon-caret-left"></i>
            </div>
          </template>
          <!-- 缺考异常 -->
          <template v-else>
            <div
              class="paper-item absent-list"
              :class="{ on: absent == 1 }"
              @click="checkAbsent(1)"
            >
              确认缺考 <span>{{ absentNums }}</span>
              <i class="el-icon-caret-left"></i>
            </div>
            <div
              class="paper-item absent-list"
              :class="{ on: absent == 0 }"
              @click="checkAbsent(0)"
            >
              确认非缺考 <span>{{ unAbsentNums }}</span>
              <i class="el-icon-caret-left"></i>
            </div>
          </template>
        </template>
      </template>
      <template v-else>
        <div
          v-for="(v, i) in GET_SCAN_ERR_LIST"
          :key="i"
          class="paper-item"
          :class="{ on: GET_INDEX_SCAN_ERR.id == v.id }"
          @click="checkIndexPaper(v)"
        >
          {{ v.deviceId }}-{{ v.batch }}-第{{ v.sequenceOrder }}张
          <span v-if="v.isTrim" style="color: red">已裁切卡</span>
          <div v-if="GET_INDEX_SCAN_TYPE == 7">
            考号：{{ v.studentExamId }} {{ v.studentName }}
          </div>
          <div v-if="GET_INDEX_SCAN_TYPE == 6 && GET_IS_HANDLE == 1">
            姓名：{{ v.studentName }}
          </div>
          <div v-if="GET_INDEX_SCAN_TYPE == 6">考号：{{ v.studentExamId }}</div>

          <i class="el-icon-caret-left"></i>
          <el-checkbox-group v-if="isBatch" v-model="checkAllList">
            <el-checkbox :label="v.id"></el-checkbox>
          </el-checkbox-group>
        </div>
      </template>
    </div>
    <!-- 图像不完整 -->
    <div v-if="GET_INDEX_SCAN_TYPE == 2" class="btn-box">
      <template v-if="recycleBin">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          :loading="btnLoading"
          @click="clearAll()"
        >
          清空
        </el-button>
        <el-button plain :loading="btnLoading" @click="checkRecycleBin()">
          关闭
        </el-button>
        <el-button
          type="primary"
          plain
          icon="el-icon-circle-check"
          class="btn-style"
          :loading="btnLoading"
          @click="restore"
        >
          还原图像
        </el-button>
      </template>
      <template v-else>
        <el-button
          type="primary"
          plain
          icon="el-icon-circle-check"
          :loading="btnLoading"
          @click="setErrorSucc"
        >
          图像完整
        </el-button>
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          :loading="btnLoading"
          @click="delImg"
        >
          删除图像
        </el-button>
        <el-button
          plain
          class="btn-style"
          icon="el-icon-delete"
          :loading="btnLoading"
          @click="checkRecycleBin"
        >
          回收站
        </el-button>
      </template>

      <!-- </div> -->
    </div>
    <!-- 定位异常 -->
    <div v-if="GET_INDEX_SCAN_TYPE == 3" class="btn-box postion-btn-box">
      <!-- <el-input
        v-model="student"
        placeholder="请输入内容"
        class="input-with-select"
      >
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input> -->
      <el-autocomplete
        v-if="showExamStudent"
        v-model="student"
        class="input-width"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入学生名/考号搜索学生"
        @select="handleSelect"
        @input="getexamstudent"
        @blur="setOldKey"
      >
        <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
      </el-autocomplete>
      <el-button
        type="danger"
        plain
        icon="el-icon-warning-outline"
        :disabled="isBatch"
        :loading="btnLoading"
        @click="check"
      >
        标记异常
      </el-button>
      <el-button
        type="danger"
        plain
        icon="el-icon-refresh-left"
        :loading="btnLoading"
        @click="dialogVisible = true"
      >
        转移学科
      </el-button>
      <el-button
        type="danger"
        plain
        icon="el-icon-delete"
        :loading="btnLoading"
        @click="delAbnormal()"
      >
        删除答卷
      </el-button>
      <el-button
        plain
        class="btn-style"
        icon="el-icon-full-screen"
        :loading="btnLoading"
        type="primary"
        @click="recognitionLocation"
      >
        重新识别
      </el-button>
    </div>
    <!-- 学科异常 -->
    <div v-if="GET_INDEX_SCAN_TYPE == 5" class="btn-box">
      <el-button
        type="primary"
        plain
        icon="el-icon-circle-check"
        :loading="btnLoading"
        @click="dialogVisible = true"
      >
        转移学科
      </el-button>
      <el-button
        type="danger"
        plain
        icon="el-icon-delete"
        :loading="btnLoading"
        @click="delAbnormal"
      >
        删除答卷
      </el-button>
      <el-button
        plain
        class="btn-style"
        icon="el-icon-circle-check"
        :loading="btnLoading"
        type="primary"
        @click="subjectSucc"
      >
        设为正常
      </el-button>

      <!-- </div> -->
    </div>
    <!-- 标题异常 -->
    <div v-if="GET_INDEX_SCAN_TYPE == 15" class="btn-box">
      <el-button
        type="primary"
        plain
        icon="el-icon-circle-check"
        :loading="btnLoading"
        @click="dialogVisible = true"
      >
        转移学科
      </el-button>
      <el-button
        type="danger"
        plain
        icon="el-icon-delete"
        :loading="btnLoading"
        @click="delAbnormal"
      >
        删除答卷
      </el-button>
      <el-button
        plain
        class="btn-style"
        icon="el-icon-circle-check"
        :loading="btnLoading"
        type="primary"
        @click="subjectSucc"
      >
        设为正常
      </el-button>

      <!-- </div> -->
    </div>
    <!-- 考号填涂异常 -->
    <div v-if="GET_INDEX_SCAN_TYPE == 6" class="btn-box">
      <el-button
        type="primary"
        plain
        icon="el-icon-full-screen"
        :loading="btnLoading"
        :disabled="GET_IS_HANDLE == 1"
        @click="studentexamid()"
      >
        重新识别
      </el-button>
      <el-button
        type="danger"
        plain
        icon="el-icon-delete"
        :loading="btnLoading"
        @click="delAbnormal"
      >
        删除答卷
      </el-button>
      <!--     -->
      <el-autocomplete
        v-model="student"
        style="margin: 20px 0 0 0"
        :disabled="GET_IS_HANDLE == 1"
        class="input-width"
        :loading="btnLoading"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入学生名/考号搜索学生"
        @select="handleSelect"
        @input="getexamstudent"
        @blur="setOldKey"
      ></el-autocomplete>

      <!-- </div> -->
    </div>
    <!-- 考号重复 -->
    <div v-if="GET_INDEX_SCAN_TYPE == 7" class="btn-box">
      <el-button
        type="primary"
        plain
        icon="el-icon-full-screen"
        :loading="btnLoading"
        :disabled="GET_IS_HANDLE == 1"
        @click="repeatexamstudentidStudentexamid()"
      >
        重新识别
      </el-button>
      <el-button
        type="danger"
        plain
        icon="el-icon-delete"
        :loading="btnLoading"
        @click="delAbnormalA()"
      >
        删除答卷
      </el-button>
      <!--      -->
      <el-autocomplete
        v-model="student"
        style="margin: 20px 0 0 0"
        :disabled="GET_IS_HANDLE == 1"
        class="input-width"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入学生名/考号搜索学生"
        @select="handleSelect"
        @input="getexamstudent"
        @blur="setOldKey"
      ></el-autocomplete>

      <!-- </div> -->
    </div>
    <!-- 缺考异常 -->
    <div v-if="GET_INDEX_SCAN_TYPE == 13" class="btn-box">
      <el-button
        plain
        class="btn-style"
        icon="el-icon-circle-check"
        :loading="btnLoading"
        type="primary"
        @click="missingSucc"
      >
        提交本页
      </el-button>
    </div>
    <!-- ab卷异常 -->
    <div v-if="GET_INDEX_SCAN_TYPE == 11" class="btn-box">
      <el-button
        plain
        class="btn-style"
        icon="el-icon-circle-check"
        :loading="btnLoading"
        type="primary"
        @click="abSucc"
      >
        提交本页
      </el-button>
    </div>
    <el-dialog title="转移学科" :visible.sync="dialogVisible" width="30%">
      <span>
        <!-- {{ $route.query.examPaperId }} -->
        学科：<el-select v-model="checkSubjuect" placeholder="请选择">
          <template v-for="item in GET_SUBJECT_LIST">
            <el-option
              v-if="$route.query.examPaperId != item.value"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </template> </el-select
      ></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="checkSubject">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 批量删除时 输入密码 -->
    <el-dialog title="温馨提示" :visible.sync="delPassword" width="400px">
      <div>此操作将永久删除图像, 删除后不可恢复，是否继续?</div>
      <div class="password">
        <span>请输入登录密码：</span>
        <el-input
          v-model="password"
          autocomplete="new-password"
          placeholder="请输入登录密码"
          show-password
          @keyup.enter.native="monitorBack"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(delPassword = false) && (checkAllList = [])">
          取 消
        </el-button>
        <el-button
          type="primary"
          :loading="modelLoading"
          @click="monitorBack()"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { encryption } from "@/core/util/util";
import {
  deleteRecyclebin,
  setErrortype,
  getRecyclebin,
  deletebatch,
  reductioRecyclebin,
  scanrecorderrorSetabnormal,
  getexamstudent,
  abnormalPositioning,
  transferSubject,
  recognitionLocation,
  settonormal,
  setabnormalTostudent,
  studentexamid,
  repeatexamstudentidStudentexamid,
  repeatexamstudentidTostudent,
  absentornotabsentBatch,
  absenterrorNums,
  getABtypeNum,
  abtypeBatch,
  settitlenormal,
} from "@/core/api/exam/examScanSheet";
export default {
  name: "SacnErrEdit",
  props: {
    sheetInfomation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      student: "",
      password: "",
      btnLoading: false,
      delPassword: false,
      modelLoading: false,
      // 是否是回收站
      recycleBin: false,
      // 是否是批量操作
      isBatch: false,
      // 是否全选
      checkedAll: false,
      checkAllList: [],
      studentList: [],
      // 显示异常学生姓名
      showExamStudent: false,
      dialogVisible: false,
      checkSubjuect: "",
      absent: 1,
      unAbsentNums: 0,
      absentNums: 0,
    };
  },

  computed: {
    ...mapGetters([
      "GET_SCAN_ERR_LIST",
      "GET_INDEX_SCAN_ERR",
      "GET_INDEX_SCAN_TYPE",
      "GET_SUBJECT_LIST",
      "GET_ABNORMAL_LIST",
      "GET_IS_HANDLE",
      "GET_SIGN_ERR_LIST",
      "GET_INDEX_SCAN_TYPE",
      "GET_INDEX_SHEET_MARK_LIST_SCAN",
      "GET_INDEX_TIME",
    ]),
  },
  watch: {
    checkAllList: {
      deep: true,
      handler() {
        this.$store.commit("SET_CHECK_ALL", this.checkAllList);
      },
    },
    isBatch: {
      deep: true,
      handler() {
        this.$store.commit("SET_ISBATCH", this.isBatch);
      },
    },
    GET_INDEX_SCAN_TYPE: {
      deep: true,
      handler() {
        this.isBatch = false;
        this.recycleBin = false;
        this.checkedAll = false;
        this.checkAllList = [];
      },
    },
  },
  created() {
    // 如果是缺考异常  需要处理下
    if (this.GET_INDEX_SCAN_TYPE == 13 && this.GET_IS_HANDLE == 1) {
      this.absenterrorNums();
    }
    if (this.GET_INDEX_SCAN_TYPE == 11) {
      this.getABtypeNum();
    }
  },
  methods: {
    checkAbsent(val) {
      if (this.absent == val) {
        return;
      }
      this.absent = val;
      this.$store.commit("SET_CURRENT", 1);
      this.$store.commit("SET_SIGN_ERR_LIST", []);
      this.$emit("getErrorList", this.absent);
    },
    // 获取ab卷异常数量
    getABtypeNum() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      getABtypeNum(data).then((res) => {
        this.unAbsentNums = res.data.data.count2;
        this.absentNums = res.data.data.count1;
      });
    },
    // 获取缺考异常处理分类数量
    absenterrorNums() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      absenterrorNums(data).then((res) => {
        this.unAbsentNums = res.data.data[0];
        this.absentNums = res.data.data[1];
      });
    },
    abSucc() {
      this.btnLoading = true;
      abtypeBatch(this.GET_SCAN_ERR_LIST)
        .then(() => {
          this.$emit("getErrorList", this.absent);
          this.$store.commit("SET_SIGN_ERR_LIST", []);
          this.getABtypeNum();
          this.$message({
            message: "设置成功",
            type: "success",
          });
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    missingSucc() {
      if (this.GET_SCAN_ERR_LIST.length == 0) {
        return;
      }
      let subArr = [];
      this.GET_SCAN_ERR_LIST.forEach((item) => {
        if (this.GET_SIGN_ERR_LIST.indexOf(item.id) != -1) {
          subArr.push({
            id: item.id,
            absent: 2,
          });
        } else {
          subArr.push({
            id: item.id,
            absent: 3,
          });
        }
      });
      this.btnLoading = true;
      absentornotabsentBatch(subArr)
        .then(() => {
          this.$emit("getErrorList", this.absent);
          this.$store.commit("SET_SIGN_ERR_LIST", []);
          this.absenterrorNums();
          this.$message({
            message: "设置成功",
            type: "success",
          });
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
      // console.log(subArr);
    },
    repeatexamstudentidStudentexamid() {
      let data = [];
      if (this.checkAllList.length == 0 && this.isBatch) {
        this.$message({
          message: "请选择重新识别的答题卡",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (this.isBatch) {
        data = this.checkAllList;
        //
      } else {
        data.push(this.GET_INDEX_SCAN_ERR.id);
      }
      this.btnLoading = true;
      repeatexamstudentidStudentexamid(data)
        .then(() => {
          this.btnLoading = false;
          this.$emit("getErrorList");
          this.$message({
            message: "重新识别中，请稍候刷新查看",
            type: "warning",
            showClose: true,
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 切换处理状态
    checkHander(val) {
      this.$store.commit("SET_IS_HANDLE", val);
      this.$emit("getErrorList", this.absent);
      if (this.GET_INDEX_SCAN_TYPE == 13 && this.GET_IS_HANDLE == 1) {
        this.absenterrorNums();
      }
      if (this.GET_INDEX_SCAN_TYPE == 11) {
        this.getABtypeNum();
      }
    },
    settitlenormal(data) {
      this.btnLoading = true;
      settitlenormal(data)
        .then(() => {
          this.btnLoading = false;
          this.$emit("getErrorList");
          this.$message({
            message: "重新识别中，请稍候刷新查看",
            type: "warning",
            showClose: true,
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    subjectSucc() {
      let data = [];
      if (this.isBatch) {
        data = this.checkAllList;
        //
      } else {
        data.push(this.GET_INDEX_SCAN_ERR.id);
      }
      if (data.length == 0 || data[0] == null) {
        this.$message({
          message: "请选择需要处理的数据！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.$confirm("未修改模板设置为正常仍然可能失败，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.GET_INDEX_SCAN_TYPE == 5) {
            this.settonormal(data);
          }
          if (this.GET_INDEX_SCAN_TYPE == 15) {
            this.settitlenormal(data);
          }
        })
        .catch(() => {
          //
        });
    },
    settonormal(data) {
      this.btnLoading = true;
      settonormal(data)
        .then(() => {
          this.btnLoading = false;
          this.$emit("getErrorList");
          this.$message({
            message: "重新识别中，请稍候刷新查看",
            type: "warning",
            showClose: true,
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 重新识别
    recognitionLocation() {
      let data = {
        ids: [],
      };
      if (this.checkAllList.length == 0 && this.isBatch) {
        this.$message({
          message: "请选择重新识别的答题卡",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (this.isBatch) {
        data.ids = this.checkAllList;
        //
      } else {
        data.ids.push(this.GET_INDEX_SCAN_ERR.id);
      }
      // console.log(this.GET_INDEX_SHEET_MARK_LIST_SCAN);
      let array = new Array();
      this.GET_INDEX_SHEET_MARK_LIST_SCAN.map((item) => {
        item.markLocation.map((index) => {
          index.source.priority = index.priority;
          index.source.id = index.id;
          array.push(index.source);
        });
      });
      data.locationArr = JSON.stringify(array);
      data.timestamp = this.GET_INDEX_TIME;
      if (data.ids.length == 0 || data.ids[0] == null) {
        this.$message({
          message: "请选择需要处理的数据！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.btnLoading = true;
      recognitionLocation(data)
        .then(() => {
          this.btnLoading = false;
          this.$message({
            message: "重新识别中，请稍候刷新查看",
            type: "warning",
            showClose: true,
          });
          this.$emit("getErrorList");
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 批量重新识别考号填涂异常的答题卡
    studentexamid() {
      let data = [];
      if (this.checkAllList.length == 0 && this.isBatch) {
        this.$message({
          message: "请选择重新识别考号填涂异常的答题卡",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (this.isBatch) {
        data = this.checkAllList;
        //
      } else {
        data.push(this.GET_INDEX_SCAN_ERR.id);
      }
      if (data.length == 0 || data[0] == null) {
        this.$message({
          message: "请选择需要处理的数据！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.btnLoading = true;
      studentexamid(data)
        .then(() => {
          this.btnLoading = false;
          this.$message({
            message: "重新识别中，请稍候刷新查看",
            type: "warning",
            showClose: true,
          });
          this.$emit("getErrorList");
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 转移学科
    checkSubject() {
      if (!this.checkSubjuect) {
        this.$message({
          message: "请选择需要转移的学科",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let data = {};
      data.ids = [];
      data.examPaperId = this.checkSubjuect;
      data.errorTypeId = this.GET_IS_HANDLE;
      // console.log(this.GET_INDEX_SCAN_ERR);
      if (this.isBatch) {
        data.ids = this.checkAllList;
        //
      } else {
        data.ids.push(this.GET_INDEX_SCAN_ERR.id);
      }
      // console.log(data);
      if (data.ids.length == 0 || data.ids[0] == null) {
        this.$message({
          message: "请选择需要处理的数据！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.btnLoading = true;
      transferSubject(data)
        .then(() => {
          this.dialogVisible = false;
          this.btnLoading = false;
          this.$emit("getErrorList");
          this.$message({
            message: "转移成功",
            type: "success",
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    check() {
      if (this.showExamStudent) {
        this.showExamStudent = false;
      } else {
        this.showExamStudent = true;
      }
    },
    // 记录下input最后输入的值
    setOldKey() {
      this.oldKeyName = this.student;
      // console.log(this.student);
    },
    getexamstudent() {
      // console.log(this.student);

      if (!this.student) {
        return;
      }
      let data = {
        examPaperId: this.$route.query.examPaperId,
        keyword: this.student,
      };
      this.studentList = [];
      getexamstudent(data).then((res) => {
        // console.log(res.config.params.keyword);
        // console.log(this.student);
        if (this.student != res.config.params.keyword) return;
        res.data.data.forEach((item) => {
          this.studentList.push({
            value:
              item.name +
              (item.studentExamId ? ` [考号：${item.studentExamId}]` : "") +
              (item.classNum ? ` [班级：${item.classNum}]` : ""),
            id: item.studentId,
            studentExamId: item.studentExamId,
          });
        });
      });
    },
    handleSelect(item) {
      // console.log(this.student);
      let str = "";
      if (this.GET_INDEX_SCAN_TYPE == 3) {
        str = "确定标记异常卷吗？";
      }
      if (this.GET_INDEX_SCAN_TYPE == 6 || this.GET_INDEX_SCAN_TYPE == 7) {
        str = item.value;
      }
      // console.log(this.GET_INDEX_SCAN_ERR.id);
      if (!this.GET_INDEX_SCAN_ERR.id) {
        this.$message({
          message: "当前没有可分配的试卷",
          type: "error",
        });
        return;
      }
      if (this.GET_INDEX_SCAN_TYPE == 6 || this.GET_INDEX_SCAN_TYPE == 7) {
        const h = this.$createElement;
        this.$msgbox({
          title: "提示",
          message: h("p", null, [
            h("span", null, "确定将此试卷分配给 "),
            h("p", { style: "color: #f56c6c" }, str),
            // h("span", null, " 是否继续?"),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            let data = {
              id: this.GET_INDEX_SCAN_ERR.id,
              studentId: item.id,
              studentExamId: item.studentExamId,
            };

            if (this.GET_INDEX_SCAN_TYPE == 6) {
              this.setabnormalTostudent(data);
            }
            if (this.GET_INDEX_SCAN_TYPE == 7) {
              this.repeatexamstudentidTostudent(data);
            }
          })
          .catch(() => {
            this.student = this.oldKeyName;
            this.getexamstudent();
          });
      }
      if (this.GET_INDEX_SCAN_TYPE == 3) {
        this.$confirm(str, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let data = {
              id: this.GET_INDEX_SCAN_ERR.id,
              studentId: item.id,
              studentExamId: item.studentExamId,
            };
            if (this.GET_INDEX_SCAN_TYPE == 3) {
              this.scanrecorderrorSetabnormal(data);
            }
          })
          .catch(() => {
            this.student = this.oldKeyName;
            this.getexamstudent();
          });
      }
    },
    // 将考号重复的答题卡分给具体的学生
    repeatexamstudentidTostudent(data) {
      repeatexamstudentidTostudent(data)
        .then(() => {
          this.student = "";
          this.btnLoading = false;
          this.$message({
            message: "设置成功",
            type: "success",
          });
          this.$emit("getErrorList");
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 将考号填涂异常的答题卡分给具体的学生
    setabnormalTostudent(data) {
      this.btnLoading = true;
      setabnormalTostudent(data)
        .then(() => {
          this.btnLoading = false;
          this.student = "";
          this.$message({
            message: "设置成功",
            type: "success",
          });
          this.$emit("getErrorList");
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    querySearchAsync(queryString, cb) {
      cb(this.studentList);
    },
    scanrecorderrorSetabnormal(data) {
      this.btnLoading = true;
      scanrecorderrorSetabnormal(data)
        .then(() => {
          this.student = "";
          this.btnLoading = false;
          this.$emit("getErrorList");
          this.showExamStudent = false;
          // console.log(res);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 获取回收站列表
    getRecyclebin() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      getRecyclebin(data).then((res) => {
        if (res.data.data.length > 0) {
          this.$store.commit("SET_SCAN_ERR_LIST", res.data.data);
          this.$store.commit("SET_INDEX_SCAN_ERR", res.data.data[0]);
        } else {
          this.$store.commit("SET_SCAN_ERR_LIST", []);
          this.$store.commit("SET_INDEX_SCAN_ERR", {});
        }
      });
    },
    // 切换回收站
    checkRecycleBin(val) {
      this.checkAllList = [];
      this.checkedAll = false;
      if (val) {
        this.recycleBin = true;
        this.getRecyclebin();
      } else {
        this.$emit("getErrorList");
        this.recycleBin = false;
      }
    },
    // 全选
    changeCheck() {
      this.checkAllList = [];
      if (this.checkedAll) {
        this.GET_SCAN_ERR_LIST.forEach((item) => {
          this.checkAllList.push(item.id);
        });
      }
    },
    // 还原
    reductioRecyclebin(data) {
      this.btnLoading = true;
      // console.log(data);
      reductioRecyclebin(data)
        .then(() => {
          this.$message({
            message: "还原成功！",
            type: "success",
          });
          this.btnLoading = false;
          this.getRecyclebin();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 还原已删除
    restore() {
      let data = [];
      // data.ids = [];\
      // console.log(this.GET_INDEX_SCAN_ERR);
      if (this.isBatch) {
        data = this.checkAllList;
        //
      } else {
        data.push(this.GET_INDEX_SCAN_ERR.id);
      }
      if (data.length == 0 || data[0] == null) {
        this.$message({
          message: "请选择需要处理的数据！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.reductioRecyclebin(data);
    },
    monitorBack() {
      let data = {
        ids: [],
      };
      if (this.isBatch) {
        data.ids = this.checkAllList;
      } else {
        if (this.GET_INDEX_SCAN_ERR.id) {
          data.ids.push(this.GET_INDEX_SCAN_ERR.id);
        }
      }

      if (data.ids.length == 0 || data.ids[0] == null) {
        this.$message({
          message: "请选择需要处理的数据！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (!this.password) {
        this.$message({
          message: "请输入登录密码",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      data.password = user.password;
      if (this.GET_INDEX_SCAN_TYPE == 2) {
        this.deleteRecyclebin(data);
      }
      if (
        this.GET_INDEX_SCAN_TYPE == 7 ||
        this.GET_INDEX_SCAN_TYPE == 15 ||
        this.GET_INDEX_SCAN_TYPE == 5 ||
        this.GET_INDEX_SCAN_TYPE == 6 ||
        this.GET_INDEX_SCAN_TYPE == 3
      ) {
        this.abnormalPositioning(data);
      }
    },
    clearAll() {
      this.delPassword = true;
      this.password = "";
    },
    delImg() {
      let data = [];

      if (this.isBatch) {
        data = this.checkAllList;
        //
      } else {
        data.push(this.GET_INDEX_SCAN_ERR.id);
      }
      if (data.length == 0 || data[0] == null) {
        this.$message({
          message: "请选择需要处理的数据！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.$confirm("此操作将删除该试卷, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deletebatch(data);
        })
        .catch(() => {});
    },
    // 清空回收站
    deleteRecyclebin(data) {
      this.btnLoading = true;
      this.modelLoading = true;
      deleteRecyclebin(data)
        .then(() => {
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.checkAllList = [];
          this.btnLoading = false;
          this.modelLoading = false;
          this.delPassword = false;
          this.getRecyclebin();
        })
        .catch(() => {
          this.btnLoading = false;
          this.modelLoading = false;
        });
    },
    // 删除图像不完整
    deletebatch(data) {
      this.btnLoading = true;
      deletebatch(data)
        .then(() => {
          this.$message({
            message: "删除成功，可在回收站查看",
            type: "success",
          });
          this.btnLoading = false;
          this.$emit("getErrorList");
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 设置图像未正常
    setErrorSucc() {
      let data = [];
      if (this.isBatch) {
        data = this.checkAllList;
      } else {
        data.push(this.GET_INDEX_SCAN_ERR.id);
      }
      if (data.length == 0 || data[0] == null) {
        this.$message({
          message: "请选择需要处理的数据！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.$confirm("设置后可能定位失败, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.setErrortype(data);
        })
        .catch(() => {});
    },
    // 图像设为正常
    setErrortype(data) {
      this.btnLoading = true;
      setErrortype(data)
        .then(() => {
          this.$message({
            message: "设置成功",
            type: "success",
          });
          this.btnLoading = false;
          this.$emit("getErrorList");
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    delAbnormalA() {
      //
      let array = [];
      if (this.isBatch) {
        array = this.checkAllList;
      } else {
        array.push(this.GET_INDEX_SCAN_ERR.id);
      }
      let type = false;
      this.GET_SCAN_ERR_LIST.map((index) => {
        if (array.indexOf(index.id) != -1 && index.isTrim) {
          type = true;
        }
      });
      if (type) {
        const h = this.$createElement;
        this.$msgbox({
          title: "温馨提示",
          message: h("p", null, [
            h("span", null, "当前选中项存在 "),
            h(
              "span",
              { style: "color: #f56c6c" },
              `'已裁切卡'，删除后会导致重新裁切，阅卷等。`
            ),
            h("span", null, " 是否继续?"),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.delAbnormal();
          })
          .catch(() => {});
      } else {
        this.delAbnormal();
      }
    },
    delAbnormal() {
      if (!this.isBatch || this.checkAllList.length == 1) {
        let data = {
          ids: [],
        };
        if (this.isBatch) {
          data.ids = this.checkAllList;
        } else {
          if (this.GET_INDEX_SCAN_ERR.id) {
            data.ids.push(this.GET_INDEX_SCAN_ERR.id);
          }
        }
        if (data.ids.length == 0 || data.ids[0] == null) {
          this.$message({
            message: "请选择需要处理的数据！",
            type: "warning",
            showClose: true,
          });
          return;
        }
        this.$confirm("删除此答卷, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.abnormalPositioning(data);
          })
          .catch(() => {});
        return;
      }
      this.delPassword = true;
      this.password = "";
    },
    // 批量删除定位异常的答题卡
    abnormalPositioning(data) {
      this.btnLoading = true;
      this.modelLoading = true;
      abnormalPositioning(data)
        .then(() => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.checkAllList = [];
          this.btnLoading = false;
          this.modelLoading = false;
          this.delPassword = false;
          this.$emit("getErrorList");
        })
        .catch(() => {
          this.btnLoading = false;
          this.modelLoading = false;
        });
    },
    checkIndexPaper(val) {
      this.$store.commit("SET_INDEX_SCAN_ERR", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.sacnErrEdit {
  width: 440px;
  height: calc(100vh - 89px);
  border-left: 1px solid #e7e7e7;
  position: relative;
  background-color: #ffffff;
  flex-shrink: 0;
  .password {
    margin-top: 12px;
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
    }
  }
  .el-checkbox {
    margin-bottom: 0;
  }

  .btn-box {
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e7e7e7;
    padding: 24px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #ffffff;
    .el-button {
      width: 184px;
    }
    .btn-style {
      width: 100% !important;
      margin-top: 24px;
    }
    .el-button + .el-button {
      margin-left: 0;
    }
    .input-width {
      width: 100%;
      margin-bottom: 24px;
      .el-button {
        width: 60px;
      }
    }
  }
  .postion-btn-box {
    .el-button {
      width: 114px;
    }
  }

  .paper-item-box {
    height: calc(100vh - 380px);
    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
    }
  }
  .absent-list {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    > span {
      position: absolute;
      right: 24px;
      top: 15px;
    }
  }
  .paper-item {
    padding: 15px 0 15px 50px;
    position: relative;
    cursor: pointer;
    ::v-deep .el-checkbox-group {
      position: absolute;
      right: 24px;
      top: 15px;
      .el-checkbox__label {
        display: none;
      }
    }
    .el-icon-caret-left {
      // opacity: 0;
      display: none;
      font-size: 18px;
    }
  }
  .on {
    background: #f2f5f8;

    .el-icon-caret-left {
      position: absolute;
      display: block;
      left: 29px;
      top: 16px;
    }
  }
  .sacn-err-header {
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
    .check-tab {
      display: flex;
      div {
        padding-bottom: 8px;
        border-bottom: 1px solid #ffffff;
        line-height: 14px;
        cursor: pointer;
        &:first-child {
          margin-right: 24px;
        }
      }
      .check-tab-on {
        color: $primary-color;
        border-bottom: 1px solid $primary-color;
      }
    }
    .sacn-err-header-title {
      margin-bottom: 24px;
      width: 100%;
    }
    span {
      border-bottom: 1px solid #e7e7e7;
      cursor: pointer;
    }
  }
}
</style>
